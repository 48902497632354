<div class="main-container">
    <div class="main-container-header">
        <h2 class="page-title-header">{{'custom_poll_main_reporting_btn2' | translate}}</h2>
        <div class="action-category-dropdown">
            <p-dropdown [(ngModel)]="sortByCategory" [options]="actionsCategoriesArray" (onChange)="selectCategoryForActions($event)"
                tooltipPosition="left" dropdownIcon="bi bi-caret-down-fill" optionLabel="name">
               <ng-template pTemplate="selectedItem">
                <div class="sort-item sort-item-value">
                  <div>{{sortByCategory?.name}}</div>
                </div>
              </ng-template>
              <ng-template let-sort pTemplate="item">
                <div class="sort-item">
                  <div>{{sort.name}}</div>
                </div>
              </ng-template>
            </p-dropdown>
        </div>
    </div>
    <div [style]="{'padding':'10rem 2.3rem','align-items':'center'}" *ngIf="actionsArray.length === 0" class="actions-main-container">
        <h2 class="no-actions-text">{{'custom_poll_create_action_no_actions' | translate}}</h2>
    </div>
    <div *ngIf="actionsArray.length > 0" class="actions-main-container">
        <div *ngFor="let action of actionsArray" class="action-container">
            <div class="action-container-header">
                <div class="d-flex align-items-center">
                    <img src="../../../../../assets/svg/action-active.svg">
                    <h2 class="action-title">{{action.title}}</h2>
                </div>
                <div class="assignee-container">
                    <span style="font-size: 1.6rem;color:#9EA3AF">{{'custom_poll_create_action_assigned_to' | translate}}</span>
                    <img style="width: 3.2rem;height: 3.2rem;border-radius: 50%;" [src]="action.assignee.avatar !== null ? this.data.baseUrl+ action.assignee.avatar.path:'../../../../../assets/svg/user-icon.svg'" alt="user">
                    <span style="font-size: 1.6rem;">{{ action.assignee.detail.displayName ? action.assignee.detail.displayName : 
                            action.assignee.detail.firstname ? action.assignee.detail.firstname + action.assignee.detail.lastname : 
                            action.assignee.email}}</span>
                    <span style="color: #9EA3AF;font-size: 1.6rem;margin-left: 3rem;">{{'custom_poll_create_action_deadline' | translate}}</span>
                    <span style="font-size: 1.6rem;">{{action.dueDate.date | date: 'dd.MM.yyyy'}}</span>
                </div>
            </div>
            <div class="action-container-body">
                <span style="font-size: 1.6rem;color: #A2A2A2;">{{action.description}}</span>
            </div>
        </div>
    </div>
</div>